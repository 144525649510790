import { DKLabel } from "deskera-ui-library";
import { Component } from "react";
import ic_hero from "../assets/skills/ic_hero.png";

class SkillsSection extends Component {
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        id="skills"
        className="row parent-width align-items-start row-responsive border-radius-xxl section-padding  section-top-margin"
        style={{
          gap: "3vw",
          backgroundColor: "rgb(243, 251, 250)",
        }}
      >
        {this.renderLeftpanel()}
        {this.renderRightpanel()}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderLeftpanel() {
    return (
      <div className="column parent-width">
        <DKLabel
          text="MY SKILLS"
          className="fw-b fs-l text-app-green p-s  "
          style={{}}
        />
        <DKLabel
          text="Am I striving?<br/>To learn new things!"
          className="fw-b"
          style={{ fontSize: 60 }}
        />
        <DKLabel
          text="<i>YES,</i> gaining and improving my skills in technology and UX/UI designs day by day! "
          className="fw-b fs-xl mt-r ml-r text-app-green "
          style={{}}
        />
        <DKLabel
          text="I am a mentor for multiple tech and design team. I am always striving to improve my skills and stay up-to-date on the latest technology and design trends. <br/><br/><i><b>I work closely with CEO to revamp, reengineer existing/new products and to discuss roadmap, goals and improvements.</b></i>"
          className="mt-xl ml-r fs-m "
          style={{}}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderRightpanel() {
    return (
      <div className="column parent-width position-relative">
        <img src={ic_hero} alt="Hero.png" className=" parent-width" />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
}

export default SkillsSection;
