import screenshot_1 from "../../assets/projects/go/screenshot_1.png";
import screenshot_2 from "../../assets/projects/go/screenshot_2.png";
import screenshot_3 from "../../assets/projects/go/screenshot_3.png";
import screenshot_4 from "../../assets/projects/go/screenshot_4.png";
import screenshot_5 from "../../assets/projects/go/screenshot_5.png";
import screenshot_6 from "../../assets/projects/go/screenshot_6.png";
import screenshot_7 from "../../assets/projects/go/screenshot_7.png";
import screenshot_8 from "../../assets/projects/go/screenshot_8.png";

import logo from "../../assets/projects/go/logo.png";

export const go_data = {
  logo: logo,
  app_name: "Go Dashboard",
  short_description: "Admin center to manage user and subscriptions",
  description:
    "This is an admin center where custmer can view and edit their details like email, phone. They can also view plan details they are subscribed too.",
  extra_info: `
    • View & edit personal details like email, phone.
    
    • Check your current subscription and change the plan.
    
    • Get a closer look to dashboard for all main products.
    
    • Add or edit users to organisation.
    
    • Manage user roles and rights.
    
    • Enable/disable two factor authentication.
    
    • Delete or reset your company.
    
    • Change password.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
    screenshot_6,
    screenshot_7,
    screenshot_8,
  ],
  my_role: "Product Owner  |  Tech Lead  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "Project Planning",
    "UX/UI",
  ],
  web_url: "https://go.deskera.com/",
};
