import { DKIcon, DKLabel } from "deskera-ui-library";
import { Component } from "react";

import {
  company_data_1,
  company_data_2,
  company_data_3,
  company_data_4,
  company_data_5,
  company_data_6,
  company_data_7,
  company_data_8,
} from "../data_provider/companies";

class Experience extends Component {
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
    this.company_data_1 = company_data_1;
    this.company_data_2 = company_data_2;
    this.company_data_3 = company_data_3;
    this.company_data_4 = company_data_4;
    this.company_data_5 = company_data_5;
    this.company_data_6 = company_data_6;
    this.company_data_7 = company_data_7;
    this.company_data_8 = company_data_8;
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        id="experience"
        className="column parent-width align-items-center border-radius-xxl section-padding section-top-margin"
        style={{
          backgroundColor: "rgb(244, 243, 240)",
        }}
      >
        {this.renderHeader()}
        <div className="column align-items-center width-80 mt-xxl ">
          {this.getCompanyDetails(this.company_data_1)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_2)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_3)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_4)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_5)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_6)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_7)}
          {this.getLine()}
          {this.getCompanyDetails(this.company_data_8)}
        </div>
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderHeader() {
    return (
      <div className="column align-items-center mb-xl">
        <DKLabel
          text="WORK EXPERIENCE"
          className="fw-b fs-l opacity-8 text-orange   "
          style={{}}
        />
        <DKLabel
          text="Companies I worked with"
          className="fw-b mt-m opacity-9 text-align-center bhb"
          style={{ fontSize: 54 }}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  getCompanyDetails(data) {
    return (
      <div className="bg-white border-radius-r column border-s company-section-padding parent-width">
        <div className="row align-items-start">
          <DKIcon src={data.logo} className="ic-l border-radius-s" />
          <div className="column flex-1 ml-m">
            <DKLabel text={data.designation} className="fw-b fs-l" />
            <DKLabel text={data.company} className="mt-s fs-m" />
            <DKLabel text={data.period} className="mt-s text-gray" />
            <DKLabel text={data.location} className="mt-s text-gray" />
          </div>
        </div>

        <div className="row align-items-start">
          <DKIcon
            src={data.logo}
            className="ic-l border-radius-s experience-logo-display opacity-0"
          />
          <div className="column flex-1 ml-r">
            <DKLabel
              text={data.responsibilities}
              className=" "
              style={{ lineHeight: 1.8 }}
            />
          </div>
        </div>
      </div>
    );
  }

  getLine() {
    return (
      <div
        className="bg-gray ml-xxl opacity-2 align-self-start"
        style={{ width: 1.4, height: 40 }}
      />
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
}

export default Experience;
