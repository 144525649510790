import { DKLabel } from "deskera-ui-library";
import { Component } from "react";
import ProjectDetailsPopup from "./ProjectDetailsPopup";
import ProjectInfo from "./ProjectInfo";
import ProjectScreenshots from "./ProjectScreenshots";

import { crm_data } from "../data_provider/projects/crm";
import { erp_data } from "../data_provider/projects/erp";
import { mrp_data } from "../data_provider/projects/mrp";
import { go_data } from "../data_provider/projects/go";
import { report_builder_data } from "../data_provider/projects/report-builder";
import { library_data } from "../data_provider/projects/library";
import { taskify_data } from "../data_provider/projects/taskify";
import { mappy_data } from "../data_provider/projects/mappy";
import { penny_data } from "../data_provider/projects/penny";
import { chatbot_data } from "../data_provider/projects/chatbot";

class ProjectsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectIndex: 0,
      projectData: crm_data,
      needDetailsPopup: false,
      needScreenshotPopup: false,
    };

    this.projects = [
      {
        title: "CRM",
        data: crm_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "MRP",
        data: mrp_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "ERP",
        data: erp_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Go Dashboard",
        data: go_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Report Builder",
        data: report_builder_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Taskify App",
        data: taskify_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Mappy App",
        data: mappy_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Penny App",
        data: penny_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "Chatbot",
        data: chatbot_data,
        isFounder: false,
        imageIndex: 0,
      },
      {
        title: "UI Library",
        data: library_data,
        isFounder: false,
        imageIndex: 0,
      },
    ];
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        id="projects"
        className="column parent-width align-items-center border-radius-xxl section-padding section-top-margin"
        style={{
          backgroundColor: "rgb(251, 245, 248)",
        }}
      >
        {this.renderHeader()}
        {this.renderProjectList()}
        {this.state.needDetailsPopup && this.renderDetailsPopup()}
        {this.state.needScreenshotPopup && this.renderScreenshotPopup()}
        <div
          className="row parent-width align-items-start row-responsive "
          style={{ gap: "3vw", marginTop: "4vw" }}
        >
          {this.state.projectData && this.renderLeftpanel()}
          {this.state.projectData && this.renderRightpanel()}
        </div>
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderDetailsPopup() {
    return (
      <ProjectDetailsPopup
        data={this.state.projectData}
        onClose={() => {
          this.showHideDetailPopup(false);
        }}
      />
    );
  }

  showHideDetailPopup(visibility = false) {
    this.setState({ needDetailsPopup: visibility });
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderScreenshotPopup() {
    return (
      <div className="transparent-background">
        <div
          className="column popup-window align-items-center"
          style={{
            maxWidth: "95%",
            maxHeight: "95%",
            padding: 0,
            borderRadius: 8,
          }}
        >
          <ProjectScreenshots
            data={this.state.projectData.screenshots}
            needCloseButton={true}
            isInsidePopup={true}
            index={this.projects[this.state.projectIndex].imageIndex}
            onClose={() => {
              this.showHideScreenshotPopup(false);
            }}
            onNextPrevClick={(imageIndex) => {
              this.projects[this.state.projectIndex].imageIndex = imageIndex;
            }}
          />
        </div>
      </div>
    );
  }

  showHideScreenshotPopup(visibility = false) {
    this.setState({ needScreenshotPopup: visibility });
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderHeader() {
    return (
      <div className="column align-items-center">
        <DKLabel
          text="MY PROJECTS"
          className="fw-b fs-l opacity-8 text-app-pink   "
          style={{}}
        />
        <DKLabel
          text="As Owner, Tech Lead, UX/UI Designer"
          className="fw-b mt-m opacity-9 text-align-center bhb"
          style={{ fontSize: 54 }}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderProjectList() {
    return (
      <div
        className="row flex-wrap justify-content-center mt-xxl align-items-start "
        style={{}}
      >
        {this.projects.map((project, index) => {
          return this.renderProjectButton(this.projects[index], index);
        })}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderProjectButton(project, index) {
    return (
      <div
        className="column cursor-hand p-h-xl p-v-m"
        onClick={() => {
          this.setState({
            projectIndex: index,
            projectData: project.data,
          });
        }}
      >
        <DKLabel text={project.title} className="fw-b fs-m" />
        {index === this.state.projectIndex && (
          <div
            className="column bg-app-pink parent-width mt-xs"
            style={{ height: 3 }}
          />
        )}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderLeftpanel() {
    return (
      <div className="column project-left-section-width">
        <ProjectInfo
          data={this.state.projectData}
          onKnowMore={() => {
            this.showHideDetailPopup(true);
          }}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderRightpanel() {
    return (
      <div className="column project-right-section-width">
        <ProjectScreenshots
          index={this.projects[this.state.projectIndex].imageIndex}
          data={this.state.projectData.screenshots}
          onZoom={() => {
            this.showHideScreenshotPopup(true);
          }}
          onNextPrevClick={(imageIndex) => {
            this.projects[this.state.projectIndex].imageIndex = imageIndex;
          }}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
}

export default ProjectsSection;
