import { DKLabel } from "deskera-ui-library";
import { Component } from "react";
import ic_hero_4 from "../assets/ic_hero_4.png";

class About extends Component {
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        className="row parent-width bg-white align-items-start text-dark-gray row-responsive pl-xxl"
        style={{ marginTop: "6vw", gap: "3vw" }}
      >
        {this.renderLeftpanel()}
        {this.renderRightpanel()}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderLeftpanel() {
    return (
      <div className="column about-left-section-width">
        <DKLabel
          text="Bringing Your Ideas<br/>To Life Through<br/>Technology & UX/UI"
          className="fw-b"
          style={{ fontSize: 60 }}
        />
        <DKLabel
          text="Let's do this together!"
          className="fw-b fs-xl mt-r ml-r text-app-purple "
          style={{}}
        />
        <DKLabel
          text="I am a programmer with a passion for creating intuitive and visually appealing user interfaces. I have a strong understanding of code architecture, design principles, project planning. I work closely with developers and designers to bring projects to a life.<br/><br/>I have had the opportunity to build projects in variety of domains like <b><i>Accounting, CRM, ERP, MRP, HRMS, Project Management, Mind Mapping, Dashboards, Broking, Expense manager and Report Builder.</i></b> </br></br>I have extensive knowledge of mobile and web development with having expertise in <b><i>Android, iOS, Swift, ChatGPT, ReactJS, React Native, Javascript, CSS, HTML, MongoDB, AWS, NodeJS, UX & UI design etc. </i></b>"
          className="mt-xl ml-r fs-m "
          style={{}}
        />
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  renderRightpanel() {
    return (
      <div className="column about-right-section-width position-relative">
        <img src={ic_hero_4} alt="Hero.png" className=" parent-width" />
        {/* <img
          src={ic_hero_2}
          alt="Hero.png"
          className=" parent-width position-absolute shadow-l border-s"
          style={{ top: 0, left: 0 }}
        />
        <img
          src={ic_hero}
          alt="Hero.png"
          className=" parent-width position-absolute border-radius-m"
          style={{ top: 50, left: 50 }}
        /> */}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
}

export default About;
