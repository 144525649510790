import screenshot_1 from "../../assets/projects/crm/screenshot_1.png";
import screenshot_2 from "../../assets/projects/crm/screenshot_2.png";
import screenshot_3 from "../../assets/projects/crm/screenshot_3.png";
import screenshot_4 from "../../assets/projects/crm/screenshot_4.png";
import screenshot_5 from "../../assets/projects/crm/screenshot_5.png";
import screenshot_6 from "../../assets/projects/crm/screenshot_6.png";
import screenshot_7 from "../../assets/projects/crm/screenshot_7.png";
import screenshot_8 from "../../assets/projects/crm/screenshot_8.png";
import screenshot_9 from "../../assets/projects/crm/screenshot_9.png";
import screenshot_10 from "../../assets/projects/crm/screenshot_10.png";
import screenshot_11 from "../../assets/projects/crm/screenshot_11.png";

import logo from "../../assets/projects/crm/logo.png";

export const crm_data = {
  logo: logo,
  app_name: "Deskera CRM",
  short_description: "Customer Relationship Management",
  description:
    "Segment customers, Create deal pipelines, Track customer histories and interactions, analyze customer data to target sales, automate sales tasks to reduce time and effort, create landing pages to reach more customers.",
  extra_info: `
    • Track accounts and contacts.

    • Segment customers for targeted marketing.
    
    • Manage sales pipelines.
    
    • Create landing pages for campaigns.
    
    • Create customized marketing forms.
    
    • Automate processes with rule-based protocols.
    
    • Create drip sequences for email campaigns.
    
    • Send targeted emails to customers.
    
    • Track user interactions with emails.
    
    • Monitor customer feedback.
    
    • Analyze customer behaviors.
    
    • Create custom dashboards.
    
    • Generate reports for better decision-making.
    
    • Provide role-based access to data.
    
    • Monitor customer service levels.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
    screenshot_6,
    screenshot_7,
    screenshot_8,
    screenshot_9,
    screenshot_10,
    screenshot_11,
  ],
  my_role: "Product Owner  |  Tech Lead & Architect  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "MongoDB",
    "NodeJS",
    "Project Planning",
    "UX/UI",
  ],
  web_url: "https://crmplus.deskera.com/",
};
