import screenshot_1 from "../../assets/projects/mrp/screenshot_1.png";
import screenshot_2 from "../../assets/projects/mrp/screenshot_2.png";
import screenshot_3 from "../../assets/projects/mrp/screenshot_3.png";
import screenshot_4 from "../../assets/projects/mrp/screenshot_4.png";
import screenshot_5 from "../../assets/projects/mrp/screenshot_5.png";
import screenshot_6 from "../../assets/projects/mrp/screenshot_6.png";
import screenshot_7 from "../../assets/projects/mrp/screenshot_7.png";
import screenshot_8 from "../../assets/projects/mrp/screenshot_8.png";
import screenshot_9 from "../../assets/projects/mrp/screenshot_9.png";

import logo from "../../assets/projects/mrp/logo.png";

export const mrp_data = {
  logo: logo,
  app_name: "Deskera MRP",
  short_description: "Material Resource Planning",
  description:
    "Calculate production costs, track inventory levels, plan purchasing and production needs, and streamlining your operations for maximum efficiency with Deskera MRP.",
  extra_info: `
    • Automate work order task creation and assignment, track progress, and monitor completion.

    • Anticipate future customer demand and optimize inventory levels.

    • Create production plans with ease. Assign resources, set schedules and deadlines.
    
    • Track work-in-progress (WIP) with ease, monitor production progress and identify bottlenecks quickly.
    
    • Manage product structures with ease using Multi Level Bill of Materials.
    
    • Keep track of the production of by-products and scraps with their associated costs and profits.
    
    • Easily monitor and optimize performance, improve maintenance scheduling, and automate data collection across machines.
    
    • Plan and manage materials and inventory for production.
    
    • Automate the planning, scheduling, and tracking of outsourced production with integrated tools.
    
    • Track user interactions with emails.
    
    • Monitor customer feedback.
    
    • Analyze customer behaviors.
    
    • Create custom dashboards.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
    screenshot_6,
    screenshot_7,
    screenshot_8,
    screenshot_9,
  ],
  my_role: "Tech Lead  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "Feature Planning",
    "UX/UI",
  ],
  web_url: "https://mrp.deskera.com/",
};
