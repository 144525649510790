import screenshot_1 from "../../assets/projects/penny/screenshot_1.png";
import screenshot_2 from "../../assets/projects/penny/screenshot_2.png";

import logo from "../../assets/projects/penny/logo.png";

export const penny_data = {
  logo: logo,
  app_name: "Penny",
  short_description: "Expense Manager App",
  description: `Penny is a personal expense tracker app that let you track and control your money.

- Track your expense and income
- Understand where you spend your money
- Set and keep eye on your monthly budget
- Easy and Innovative`,
  extra_info: `

    Key Features:

        • Simple, attractive and easy to understand user interface

        • Track your spendings quickly and easily

        • Set budget to achieve your goal

        • Overview of current progress of your spendings

        • Create folders to organise expenses

        • Easy and Quick to add, edit and delete your transactions

        • Assign category to transactions

        • Sort by date, amount spent, name and category

        • Smart search using date range, amount range and word

        • Filter expenses on monthly, weekly, daily and yearly basis

        • Switch between budget view and available balance view
  
  
    Penny is free app. Download and get in love with this app.`,
  screenshots: [screenshot_1, screenshot_2],
  my_role: "Founder  |  Programmer  |  UI/UX Designer",
  skills_used: ["iOS", "Objective C", "SQLite", "Project Planning", "UX/UI"],
  web_url: "https://apps.apple.com/app/expense-manager-penny/id1247753395",
};
