import { DKButton, DKIcon, DKLabel } from "deskera-ui-library";
import { Component } from "react";

import ic_profile from "../assets/ic_profile.jpg";
import ContactPopup from "./ContactPopup";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needContactPopup: false,
    };
  }
  render() {
    return (
      <div
        className="row justify-content-between parent-width bg-white align-items-start"
        style={{ marginTop: 28 }}
      >
        <div className="row width-auto flex-wrap">
          <DKIcon src={ic_profile} className="ic-l circle" />
          <DKLabel
            text="Piyush Kandrikar"
            className="fw-h fs-xxxl mr-xxl ml-r"
          />
          <a
            href="#about"
            className="text-dark-gray"
            style={{ textDecoration: "none" }}
          >
            <DKButton
              title="About"
              className="fw-b fs-m mr-r"
              onClick={() => {}}
            />
          </a>
          <a
            href="#skills"
            className="text-dark-gray"
            style={{ textDecoration: "none" }}
          >
            <DKButton
              title="Skills"
              className="fw-b fs-m mr-r"
              onClick={() => {}}
            />
          </a>
          <a
            href="#projects"
            className="text-dark-gray"
            style={{ textDecoration: "none" }}
          >
            <DKButton
              title="Projects"
              className="fw-b fs-m mr-r"
              onClick={() => {}}
            />
          </a>
          <a
            href="#experience"
            className="text-dark-gray"
            style={{ textDecoration: "none" }}
          >
            <DKButton
              title="Work Experience"
              className="fw-b fs-m mr-r"
              onClick={() => {}}
            />
          </a>
        </div>
        <div className="position-relative column">
          <DKButton
            title="Contact Me"
            className="fw-b bg-dark-gray text-white p-m"
            style={{ backgroundColor: "rgb(18, 25, 43)" }}
            onClick={() => {
              this.setState({ needContactPopup: true });
            }}
          />
          {this.state.needContactPopup && (
            <ContactPopup
              onClose={() => {
                this.setState({ needContactPopup: false });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Menu;
