import screenshot_1 from "../../assets/projects/mappy/screenshot_1.png";
import screenshot_2 from "../../assets/projects/mappy/screenshot_2.png";
import screenshot_3 from "../../assets/projects/mappy/screenshot_3.png";
import screenshot_4 from "../../assets/projects/mappy/screenshot_4.png";
import screenshot_5 from "../../assets/projects/mappy/screenshot_5.png";

import logo from "../../assets/projects/mappy/logo.png";

export const mappy_data = {
  logo: logo,
  app_name: "Mappy",
  short_description: "Mind Mapping App",
  description:
    "Mind mapping helps you to organise your thoughts, remember things and generate new ideas. We have created a beautiful, intuitive and easy to use app just for you, so that you can mind map anywhere anytime easily.<br/><br/>Mappy is a mind mapping application available on iPhone, iPad and Apple Watch. It is most popular choice for mind mapping. Use this tool to create, share and edit your mind maps.",
  extra_info: `
  Mappy is one of the best mind mapping tool for all your needs. It is useful to take a minutes of meeting, notes, project planning, grocery shopping, vacation plan , Mapify is the right choice. This app allows you to put all your ideas in one place digitally and convert them into actionable plan.


  Key Features:

    • Create unlimited maps and nodes

    • Add icon, notes, color, web link and image to node

    • Add emoji to node

    • View and edit title, notes, link and image

    • Zoom in and zoom out map

    • Move node position anywhere to design it in your way

    • Share map as png, jpg and csv

    • Search node for quick access

    • Cut, copy and paste node

    • Move group of nodes

    • Change background color of map

    • Lot of options to customise map from settings

    • Copy nodes from one map to another

    • Fastest way to create map

    • Compatible with iPhone X and latest iOS

    • Excellent technical support
  
  
  Mappy is free app. Download and get in love with this app.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
  ],
  my_role: "Founder  |  Programmer  |  UI/UX Designer",
  skills_used: ["iOS", "Objective C", "Core data", "Project Planning", "UX/UI"],
  web_url: "https://apps.apple.com/app/mind-mapping-mappy/id1476911776",
};
