import { Component } from "react";

import {} from "deskera-ui-library";

import {} from "./App.css";

import Header from "./components/header";
import Homepage from "./components/Homepage";
// import KanbanPopup from "./components/KanbanPopup";

class App extends Component {
  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    return (
      <div className="main-holder bg-white app-font align-items-center column">
        <Header />
        <Homepage />
        {/* <KanbanPopup /> */}
      </div>
    );
  }
}
export default App;
