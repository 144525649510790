import { DKIcons, DKIcon } from "deskera-ui-library";
import { Component } from "react";

/*
-data
-index
-isInsideDetailsPopup
-isInsidePopup
-onNextPrevClick(currentIndex)
*/

class ProjectScreenshots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: this.props.index || 0,
      screenshots: this.props.data,
    };
    this.needCloseButton = this.props.needCloseButton || false;
  }

  componentWillReceiveProps(props) {
    this.setState({ currentIndex: props.index || 0, screenshots: props.data });
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  componentDidMount() {
    this.registerEventListeners();
  }
  componentWillUnmount() {
    this.deregisterEventListeners();
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ///////////////////////EVENT LISTENERS//////////////////////////
  registerEventListeners() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  deregisterEventListeners() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest("#project-screenshot-popup");

      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="column justify-content-center ">
        <div
          id="project-screenshot-popup"
          className="column position-relative parent-width justify-content-center product-screenshot border-radius-s "
        >
          <img
            src={this.state.screenshots[this.state.currentIndex]}
            alt=""
            className={
              "parent-width border-m border-radius-s shadow-s unselectable " +
              (!this.props.isInsideDetailsPopup && !this.needCloseButton
                ? "cursor-zoom-in"
                : "")
            }
            onClick={() => {
              if (!this.props.isInsideDetailsPopup && !this.needCloseButton) {
                this.props.onZoom();
              }
            }}
          />
          {this.state.currentIndex > 0 && (
            <div
              className={
                " circle p-m position-absolute cursor-hand unselectable " +
                (this.props.isInsidePopup ? " arrow-button " : "")
              }
              style={{ left: 20, backgroundColor: "rgba(0,0,0,0.2)" }}
              onClick={() => {
                this.props.onNextPrevClick?.(this.state.currentIndex - 1);
                this.setState({ currentIndex: this.state.currentIndex - 1 });
              }}
            >
              <DKIcon
                src={DKIcons.white.ic_arrow_left}
                className=" unselectable"
              />
            </div>
          )}
          {this.state.currentIndex < this.state.screenshots.length - 1 && (
            <div
              className={
                " circle p-m position-absolute cursor-hand unselectable" +
                (this.props.isInsidePopup ? " arrow-button " : "")
              }
              style={{ right: 20, backgroundColor: "rgba(0,0,0,0.2)" }}
              onClick={() => {
                this.props.onNextPrevClick?.(this.state.currentIndex + 1);
                this.setState({ currentIndex: this.state.currentIndex + 1 });
              }}
            >
              <DKIcon
                src={DKIcons.white.ic_arrow_right}
                className=" unselectable"
              />
            </div>
          )}
          {/* {this.needCloseButton && this.renderCloseButton()} */}
        </div>
        {this.props.isInsideDetailsPopup && this.renderImageList()}
      </div>
    );
  }

  renderCloseButton() {
    return (
      <div
        className=" border-radius-r p-s position-absolute cursor-hand bg-white border-m shadow-s"
        style={{ right: 2, top: 5 }}
        onClick={() => {
          this.props.onClose();
        }}
      >
        <DKIcon src={DKIcons.ic_close_} className="ic-xs" />
      </div>
    );
  }

  renderImageList() {
    return (
      <div
        className="row justify-content-center flex-wrap p-r mt-xxl bg-gray1s border-ms border-radius-s width-80 align-self-center"
        style={{ gap: 25 }}
      >
        {this.state.screenshots.map((screenshot, index) => {
          return (
            <img
              src={this.state.screenshots[index]}
              style={{ height: 50 }}
              alt=""
              className="border-m cursor-hand"
              onClick={() => {
                this.setState({ currentIndex: index });
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default ProjectScreenshots;
