import { Component } from "react";
import Menu from "./Menu";
import About from "./About";
import Companies from "./Companies";
import SkillsSection from "./SkillsSection";
import ProjectsSection from "./ProjectsSection";
import Experience from "./Experience";
import Footer from "./Footer";

class Homepage extends Component {
  render() {
    return (
      <div
        className="column parent-width parent-height bg-yellow1 p-h-l hide-scroll-bar scroll-smooth"
        style={{
          maxWidth: 1350,
          color: "black",
          overflowY: "scroll",
          paddingBottom: 80,
        }}
      >
        <Menu />
        <About />
        <Companies />
        <SkillsSection />
        <ProjectsSection />
        <Experience />
        <Footer />
      </div>
    );
  }
}

export default Homepage;
