import { DKLabel, DKButton } from "deskera-ui-library";
import { Component } from "react";

class ProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data });
  }

  render() {
    let badge_class = "p-s border-radius-m shadow-s bg-white";
    return (
      <div className="column parent-width">
        {!this.props?.isInsideDetailsPopup && (
          <DKLabel
            text={this.state.data.app_name}
            className="fw-b"
            style={{ fontSize: 25 }}
          />
        )}
        {!this.props?.isInsideDetailsPopup && (
          <DKLabel
            text={this.state.data.short_description}
            className="fw-b mt-s"
            style={{ fontSize: 20 }}
          />
        )}
        {this.props?.isInsideDetailsPopup && (
          <DKLabel
            text="Project details"
            className="fw-b mt-s"
            style={{ fontSize: 18 }}
          />
        )}
        {this.state.data?.description && (
          <DKLabel
            text={this.state.data?.description}
            className=" fs-l mt-r opacity-9  "
            style={{}}
          />
        )}

        {this.props.isInsideDetailsPopup && this.renderExtraInfo()}

        <DKLabel
          text="My Role"
          className="fw-m opacity-8 fs-m mt-xxl"
          style={{}}
        />
        <DKLabel text={this.state.data?.my_role} className="mt-s " style={{}} />

        <DKLabel
          text="Skills used"
          className="fw-m opacity-8 fs-m mt-xxl"
          style={{}}
        />
        <div className="row row-responsive mt-m flex-wrap" style={{ gap: 10 }}>
          {this.state.data?.skills_used.map((skill) => {
            return <DKLabel text={skill} className={badge_class} />;
          })}
        </div>

        {!this.props?.isInsideDetailsPopup && (
          <div className="row mt-xxl">
            <DKButton
              className="bg-app-pink p-m text-white fw-m"
              title="Know more"
              onClick={() => {
                this.props.onKnowMore();
              }}
            />
            {/* <DKButton
              className=" p-m text-black ml-r fw-m "
              isReverse
              icon={DKIcons.ic_arrow_right2}
              title="Try for Free"
              onClick={() => {}}
            /> */}
          </div>
        )}
      </div>
    );
  }

  renderExtraInfo() {
    return (
      <>
        <DKLabel className="mb-l" text={this.state.data?.extra_info} />
      </>
    );
  }
}

export default ProjectInfo;
