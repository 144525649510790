import screenshot_1 from "../../assets/projects/report-builder/screenshot_1.png";
import screenshot_2 from "../../assets/projects/report-builder/screenshot_2.png";
import screenshot_3 from "../../assets/projects/report-builder/screenshot_3.png";
import screenshot_4 from "../../assets/projects/report-builder/screenshot_4.png";
import screenshot_5 from "../../assets/projects/report-builder/screenshot_5.png";

import logo from "../../assets/projects/report-builder/logo.png";

export const report_builder_data = {
  logo: logo,
  app_name: "Report Builder",
  short_description: "Build and cutomize your business reports",
  description:
    "Let your data tell the story. Compile all your business data into a single report. Select which data points to include and how they should display.",
  extra_info: `
    • Choose from multiple graphs and charts.

    • Generate reports for yourself and clients.
    
    • Gain insights from all data sources across your business.
    
    • Automate regular reporting.
    
    • Easily share reports in pdf, csv & xls formats.
    
    • Create your own dashboards.
    
    • Mark reports as your favroites.
    
    • View and save from 100s of pre-defined templates.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
  ],
  my_role: "Product Owner  |  Tech Lead  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "MongoDB",
    "NodeJS",
    "Project Planning",
    "UX/UI",
  ],
  web_url: "https://report-builder.deskera.com/",
};
