import { DKLabel } from "deskera-ui-library";
import { Component } from "react";

import company_1 from "../assets/companies/company_1-modified.png";
import company_2 from "../assets/companies/company_2-modified.png";
import company_3 from "../assets/companies/company_3-modified.png";
import company_4 from "../assets/companies/company_4-modified.png";
import company_5 from "../assets/companies/company_5-modified.png";
import company_6 from "../assets/companies/company_6-modified.png";
import company_7 from "../assets/companies/company_7-modified.png";

class Companies extends Component {
  render() {
    let height = 30;
    return (
      <div className="column parent-width align-items-center company-top-margin mb-l">
        <DKLabel text="COMPANIES I WORKED WITH" className="text-gray fw-b" />
        <div
          className="row row-responsive flex-wrap justify-content-center mt-xxl opacity-6"
          style={{ gap: 50 }}
        >
          <img
            src={company_1}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_2}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_3}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_4}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_5}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_6}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
          <img
            src={company_7}
            alt="Deskera Pte. Ltd."
            style={{ height: height }}
          />
        </div>
      </div>
    );
  }
}

export default Companies;
