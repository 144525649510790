import screenshot_1 from "../../assets/projects/chatbot/screenshot_1.png";
import screenshot_2 from "../../assets/projects/chatbot/screenshot_2.png";

import logo from "../../assets/projects/chatbot/logo.png";

export const chatbot_data = {
  logo: logo,
  app_name: "Chatbot",
  short_description: "Chat based on ChatGPT/OpenAI",
  description:
    "A chatbot is a computer program designed to simulate human conversations either through texts or voice. This conversation is done in natural format using artificial intelligence techniques such as Natural Language Processing (NLP).<br/><br/>Chatbots can respond to questions posed by the user by understanding human speech, texts as well as by judging the intent. The most interesting feature of the bots is that they learn from the past interactions and become intelligent and smarter over the time.",
  extra_info: `
  `,
  screenshots: [screenshot_1, screenshot_2],
  my_role: "Tech Advisor  |  UI/UX Designer",
  skills_used: ["OpenAI", "ChatGPT", "UX/UI"],
  web_url: "https://www.deskera.com/",
};
