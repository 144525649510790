import screenshot_1 from "../../assets/projects/library/screenshot_1.png";
import screenshot_2 from "../../assets/projects/library/screenshot_2.png";
import screenshot_3 from "../../assets/projects/library/screenshot_3.png";
import screenshot_4 from "../../assets/projects/library/screenshot_4.png";
import screenshot_5 from "../../assets/projects/library/screenshot_5.png";
import screenshot_6 from "../../assets/projects/library/screenshot_6.png";
import screenshot_7 from "../../assets/projects/library/screenshot_7.png";

import logo from "../../assets/projects/library/logo.png";

export const library_data = {
  logo: logo,
  app_name: "React UI Library",
  short_description: "The React library to build apps fast",
  description:
    "This is a react UI library using which programmers can create apps lighting fast. Deskera is using this extensively in all the projects to keep the UI/UX consistency through-out all projects.<br/><br/>It has all the basic components like label, button, inputs etc. It also has a beautiful charts built inhouse. Click on Know more to view all available components.<br/><br/>No external library has been used while making this. ",
  extra_info: `
    • Label

    • Button

    • Input

    • Calendar

    • Alert

    • Toast

    • Spiner

    • Segment control

    • Slider

    • Data grid

    • Search bar

    • Tooltip

    • Icons

    • Paginator

    • Contact icon

    • Form

    • Status

    • Badge

    • Colors

    • Font

    • Font sizes

    • Margin, padding, radius classes`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
    screenshot_6,
    screenshot_7,
  ],
  my_role: "Creator  |  Maintainer  |  Tech Lead  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "Component Planning",
    "UX/UI",
  ],
  web_url: "https://www.npmjs.com/package/deskera-ui-library",
};
