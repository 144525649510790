import { DKButton, DKIcon, DKIcons, DKLabel } from "deskera-ui-library";
import { Component } from "react";

import ProjectScreenshots from "./ProjectScreenshots";
import ProjectInfo from "./ProjectInfo";

class ProjectDetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  componentDidMount() {
    this.registerEventListeners();
  }
  componentWillUnmount() {
    this.deregisterEventListeners();
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ///////////////////////EVENT LISTENERS//////////////////////////
  registerEventListeners() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  deregisterEventListeners() {
    document.removeEventListener("mouseup", this.onMouseUp);
  }
  onMouseUp = (e) => {
    const target = e.target;
    const editingContainer = target.closest("#project-details-popup");

    if (!editingContainer) {
      this.props.onClose();
    }
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="transparent-background">
        <div
          id="project-details-popup"
          className="column popup-window"
          style={{
            width: "90%",
            maxWidth: 1200,
            height: "100%",
            maxHeight: "100%",
            borderRadius: 0,
            padding: 30,
            backgroundColor: "rgb(252, 252, 253)",
          }}
        >
          {this.renderHeader()}
          <ProjectScreenshots
            isInsidePopup={true}
            isInsideDetailsPopup={true}
            data={this.props?.data?.screenshots}
          />
          {this.renderInfo()}
          <DKButton
            className="bg-app-pink p-m text-white fw-m  ml-xxl mb-xl"
            title="Try for FREE"
            onClick={() => {
              window.open(this.props?.data?.web_url, "_blank");
            }}
          />
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="row justify-content-between align-items-start mb-xxl">
        <div className="row width-auto  align-items-start">
          <DKIcon
            src={this.props?.data?.logo}
            className="ic-m ml-s border-radius-m"
          />
          <div className="column ml-m">
            <DKLabel text={this.props?.data?.app_name} className="fw-b fs-l" />
            <DKLabel
              text={this.props?.data?.short_description}
              className=" fs-l text-gray"
            />
          </div>
        </div>
        <div className="row width-auto">
          <DKButton
            className="bg-app-pink p-m text-white fw-m mr-r"
            title="Try for FREE"
            onClick={() => {
              window.open(this.props?.data?.web_url, "_blank");
            }}
          />
          <DKButton
            className="bg-white border-ms p-m"
            icon={DKIcons.ic_close}
            onClick={() => {
              this.props.onClose();
            }}
          />
        </div>
      </div>
    );
  }

  renderInfo() {
    return (
      <div className="column mb-xl width-80  p-h-xxl pt-xxl pb-r">
        <ProjectInfo isInsideDetailsPopup={true} data={this.props?.data} />
      </div>
    );
  }
}

export default ProjectDetailsPopup;
