import screenshot_1 from "../../assets/projects/taskify/screenshot_1.png";
import screenshot_2 from "../../assets/projects/taskify/screenshot_2.png";
import screenshot_3 from "../../assets/projects/taskify/screenshot_3.png";

import logo from "../../assets/projects/taskify/logo.png";

export const taskify_data = {
  logo: logo,
  app_name: "Taskify",
  short_description: "Task Management App",
  description:
    "Taskify is a simple to-do app and task manger which helps you to get your task done. You can share your tasks while working on projects, buy grocery or planning for a vacation. It's very easy to capture, share and complete your tasks.",
  extra_info: `
  • Stunning dark mode available

  • Organise tasks with folders
  
  • Easy to capture to-dos
  
  • Set reminder and due date to your tasks
  
  • Add notes, priority and flags to your to-dos
  
  • Assign resources to your task & track the progress
  
  • Share and upload list with variety of options
  
  • Email list to your friends, family and colleagues
  
  • Move task or folder to different folders
  
  • Sort list alphabetically, by due date or created date
  
  • Beautiful user experience with simple and intuitive user interface
  
  • Calendar view gives direct and easy access to your scheduled tasks
  
  • Keep eye on your today and week tasks
  
  • Compatible with iPhone X
  
  • Passcode lock to secure access
  
  • Today's widget is available. Quickly access your today and weekly tasks from widget window.
  
  
  Taskify is free app. Download and get in love with this app.`,
  screenshots: [screenshot_1, screenshot_2, screenshot_3],
  my_role: "Founder  |  Programmer  |  UI/UX Designer",
  skills_used: ["iOS", "Swift", "Core data", "Project Planning", "UX/UI"],
  web_url: "https://apps.apple.com/app/taskify-to-do-list-tasks/id1282867263",
};
