import ic_deskera from "../assets/companies/thubnails/ic_deskera.jpg";
import ic_angelbroking from "../assets/companies/thubnails/ic_angelbroking.jpg";
import ic_i2e from "../assets/companies/thubnails/ic_i2e.jpg";
import ic_gameshastra from "../assets/companies/thubnails/ic_gameshastra.jpg";
import ic_milenine from "../assets/companies/thubnails/ic_milenine.png";
import ic_tata from "../assets/companies/thubnails/ic_tata.jpg";
import ic_hungama from "../assets/companies/thubnails/ic_hangama.jpg";
import ic_reliance from "../assets/companies/thubnails/ic_reliance.jpg";

export const company_data_1 = {
  logo: ic_deskera,
  company: "Deskera Pte. Ltd.",
  designation: "Vice President - Products",
  period: "June 2019 - Present • 4 yrs",
  location: "Singapore",
  responsibilities: `
• Redesigned, Reengineered, Revamped all the products of Deskera
• Built React UI library to use across all the products to keep the UI/UX consistency throughout 
• Improved engineering efficiency through tools and automations
• Drove cross team/department/entity resource and dependency coordination to ensure project delivery
• Built various teams including engineering, devops, QA & UX/UI
• Built multiple products as a product owner, lead programmer, a senior architect &  a UI/UX designer
• Closely working with a CEO office to share ideas and innovations 
• Involved in core development and design process
• Analysing scope of correction in existing products
• Building CRM, Accounting, HRMS, Report Builder, document designer, dashboards, subscriptions
• Helped teams to kickstart project in terms of UX/UI and code architecture
• Built & Delivered hi-fi prototypes
• Directing and mentoring multiple teams on engineering & UI/UX design`,
};

export const company_data_2 = {
  logo: ic_angelbroking,
  company: "Angel Broking · Full-time",
  designation: "Chief Technology Manager - Mobile Development",
  period: "Jan 2017 - May 2019 · 2 yrs 5 mos",
  location: "Mumbai Area, India",
  responsibilities: `
• Handled India broking apps for architecture design, core development, handling team, designing of product
• Responsible for nurturing and growing a large, diverse team of technical architects and specialists
• Coding advanced applications using Swift, Objective C, Javascript, SQL, SQLite
• Delivered products on iOS, MacOS, Apple Watch OS, Android, Web API & Cloud Services
• Responsible for delivering products on web and mobile platform
• Collaborate with product team, UX/UI, marketing, development and testing teams
• Involved in each and every feature core development & architecture design`,
};

export const company_data_3 = {
  logo: ic_i2e,
  company: "i2e Consulting",
  designation: "Technical Lead - iOS, MacOS, WatchOS",
  period: "Mar 2013 - Dec 2016 · 3 yrs 10 mos",
  location: "Mumbai Area, India",
  responsibilities: `
• Performed in a Lead developer role on enterprise apps for architecture design, R&D and Application enhancements
• Responsible for coding advanced applications using iOS, MacOS, Watch OS
• Created Cloud Service to sync data on all available platforms
• Created reusable components that can be applied to iOS applications developed throughout the organization
• Evaluate new mobile methodologies and technologies`,
};

export const company_data_4 = {
  logo: ic_gameshastra,
  company: "Gameshastra",
  designation: "Technical Lead – Mobility",
  period: "Jul 2012 - Jan 2013 · 7 mos",
  location: "Hyderabad Area, India",
  responsibilities: `
  • Performed in a senior developer role on learning apps for architectural design and Application enhancements
  • Built gaming team for kids educational games
  • Drve engineering process and pipelines 
  • Closely worked with stackholders`,
};

export const company_data_5 = {
  logo: ic_milenine,
  company: "Mile Nine Studios",
  designation: "Team Lead – Mobility & Online",
  period: "Dec 2009 - Jul 2012 · 2 yrs 8 mos",
  location: "Mumbai Area, India",
  responsibilities: `
• Performed in a senior developer role on advertise apps for coding & designing
• Worked as a technical lead to lead all gaming projects and mobile apps
• Build large team of 40 programmers and mentor them
• Was envolved in training all joinees in game development`,
};

export const company_data_6 = {
  logo: ic_tata,
  company: "Tata Interactive Systems",
  designation: "Lead Developer",
  period: "Feb 2009 - Dec 2009 · 11 mos",
  location: "Mumbai Area, India",
  responsibilities: `
• Worked as a core team member in the E learning App Department and involved in developing an Isometric Learning App Engine`,
};

export const company_data_7 = {
  logo: ic_hungama,
  company: "Hungama Digital Services",
  designation: "Senior Programmer – Web, Mobile Apps",
  period: "Aug 2008 - Jan 2009 · 6 mos",
  location: "Mumbai",
  responsibilities: `
• Performed in a senior programmer role to design and build movie related apps and games`,
};

export const company_data_8 = {
  logo: ic_reliance,
  company: "Reliance Games",
  designation: "Flash Actionscript Programmer - 2D Games",
  period: "Jan 2006 - Aug 2008 · 2 yrs 8 mos",
  location: "Pune Area, India",
  responsibilities: `
• Performed in a junior game programmer role
• Built various onlne games
• Was a member of India's first online multiplayer game`,
};
