import screenshot_1 from "../../assets/projects/erp/screenshot_1.png";
import screenshot_2 from "../../assets/projects/erp/screenshot_2.png";
import screenshot_3 from "../../assets/projects/erp/screenshot_3.png";
import screenshot_4 from "../../assets/projects/erp/screenshot_4.png";
import screenshot_5 from "../../assets/projects/erp/screenshot_5.png";
import screenshot_6 from "../../assets/projects/erp/screenshot_6.png";
import screenshot_7 from "../../assets/projects/erp/screenshot_7.png";
import screenshot_8 from "../../assets/projects/erp/screenshot_8.png";
import screenshot_9 from "../../assets/projects/erp/screenshot_9.png";
import screenshot_10 from "../../assets/projects/erp/screenshot_10.png";
import screenshot_11 from "../../assets/projects/erp/screenshot_11.png";
import screenshot_12 from "../../assets/projects/erp/screenshot_12.png";

import logo from "../../assets/projects/erp/logo.png";

export const erp_data = {
  logo: logo,
  app_name: "Deskera ERP",
  short_description: "Accounting Software",
  description:
    "Automate and centralize business processes, gain real-time insights and increase business control, increase data accuracy, reduce costs, fulfill orders faster, and improve customer service with Deskera ERP.",
  extra_info: `
    • Track transactions, create invoices, generate reports.

    • Monitor accounts receivable and payable, create custom financial year schedules, and manage multiple currencies.

    • Manage financial processes like budgeting and forecasting, bank reconciliation.
    
    • Manage your materials, resources, and production processes efficiently with Deskera's MRP Platform.
    
    • Track inventory levels and movements in real-time. Generate stock-level alerts when reaching critical low thresholds.
    
    • Automate receiving, order picking, and shipping. Manage multiple warehouses. Pick, Pack, and Ship from Warehouses.
    
    • Manage all aspects of sales orders from multiple channels.
    
    • Track, monitor, and analyze project revenue, expenses, and billable hours.
    
    • Generate business report.
    
    • Track user interactions with emails.
    
    • Monitor customer feedback.
    
    • Analyze customer behaviors.
    
    • Create custom dashboards.`,
  screenshots: [
    screenshot_1,
    screenshot_2,
    screenshot_3,
    screenshot_4,
    screenshot_5,
    screenshot_6,
    screenshot_7,
    screenshot_8,
    screenshot_9,
    screenshot_10,
    screenshot_11,
    screenshot_12,
  ],
  my_role: "Tech Lead  |  UI/UX Designer",
  skills_used: [
    "ReactJS",
    "Javascript",
    "HTML",
    "CSS",
    "Feature Planning",
    "UX/UI",
  ],
  web_url: "https://booksplus.deskera.com/",
};
